
import { defineComponent } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "QrCodePage",
  components: { GuestLayout },
  setup() {
    const router = useRouter();
    const onClickScanQrCode = () => {
      router.push({ name: "QrCodeFindDrivingSchool" });
    };
    return {
      onClickScanQrCode,
    };
  },
});
